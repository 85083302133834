import React from 'react';

import { InternalLink } from '../../components/Clickables/InternalLink/InternalLink';
import { Heading } from '../../components/Heading/Heading';
import { Text } from '../../components/Text/Text';

interface IProps {
  statusCode: number | null;
}

export const ServerError = ({ statusCode }: IProps) => (
  <div className="flex justify-center" style={{ minHeight: '60vh' }} data-testid="NotFound">
    <div className="my-16" style={{ maxWidth: '800px' }}>
      <Heading as="h1" className="mb-4">
        {statusCode ? `Error ${statusCode}` : 'Server error'}
      </Heading>
      <Text>Darn.</Text>
      <Text>You have encountered a server error. Here are some things you can try:</Text>
      <ul className="m-0 list-disc p-4">
        <li>
          Go back to the <InternalLink to="/">homepage.</InternalLink>
        </li>
        <li>Refresh the page.</li>
        <li>
          If the error persists, please send us an email at{' '}
          <a href="mailto:support@fishbrain.com">support@fishbrain.com</a> and let us know.
        </li>
      </ul>
      <Text>Thanks!</Text>
    </div>
  </div>
);
